/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from "@remix-run/react";
import { startTransition, useEffect } from "react";
import { hydrateRoot } from "react-dom/client";
import "~/lib/persistQueryClient";
import mixpanel from "mixpanel-browser";
import { mixpanelToken, sentryDsn } from "~/lib/constants";
import * as Sentry from "@sentry/remix";

Sentry.init({
  enabled: import.meta.env.PROD,
  dsn: sentryDsn,
  environment: import.meta.env.MODE,
  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
  ],
  tracesSampleRate: import.meta.env.MODE === "production" ? 0.2 : 0,
  tracePropagationTargets: [/^https:\/\/api\.mentory\.app/],
});

mixpanel.init(mixpanelToken, {
  debug: import.meta.env.DEV,
  track_pageview: true,
  persistence: "localStorage",
  api_host: "https://api-eu.mixpanel.com",
  ignore_dnt: true,
});

startTransition(() => {
  hydrateRoot(document, <RemixBrowser />);
});
